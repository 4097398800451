<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3 pt-0">
        <div class="d-flex align-items-center mb-3 pt-3">
            <h1 class="font-weight-light my-0">Test Prices</h1>
        </div>
        <div class="row d-flex align-items-center mb-4 pl-2 font-weight-light">
            <agreement-select
                class="agreement-select"
                v-model="form.agreementId"
                returnField="agreementId"></agreement-select>
            <pricing-rule-template-selector
                v-if="form.agreementId"
                class="agreement-select"
                v-model="form.pricingRuleTemplateId"
                :agreementId="form.agreementId"
                @fetched="storeTemplates"
                returnField="pricingRuleTemplateId"></pricing-rule-template-selector>
        </div>
        <mercur-card v-if="form.agreementId && form.pricingRuleTemplateId" class="bg-primary rounded">
            <div class="row">
                <div class="col">
                    <pretty-select
                        v-if="shopList.length && !loading"
                        v-model="selectedShop"
                        :options="shopList"
                        :get-option-label="shop => `${shop.applicationName} ${shop.countryName ? shop.countryName : shop.countryCode}`"
                        placeholder="Select shop"
                        data-test="TestPricesSelectShop"
                        color="white"
                    >
                        <template slot="selected-option" slot-scope="slotData">
                            <span>
                                {{ slotData.applicationName }}
                                <strong> ({{ slotData.countryName ? slotData.countryName : slotData.countryCode }})</strong>
                            </span>
                        </template>
                        <template slot="option" slot-scope="slotData">
                            <span>
                                {{ slotData.applicationName }} ({{ slotData.countryName ? slotData.countryName : slotData.countryCode }})
                            </span>
                        </template>
                    </pretty-select>
                    <mercur-spinner data-test="mappingLoadingSpinner" v-else-if="loading"></mercur-spinner>
                </div>
                <div class="col">
                    <pretty-select
                        v-if="productsList.length && !loading"
                        v-model="selectedProduct"
                        :options="productsList"
                        label="productName"
                        placeholder="Select product"
                        color="white"
                        data-test="TestPricesSelectProduct"
                    ></pretty-select>
                    <mercur-spinner data-test="mappingLoadingSpinner" v-else-if="loading"></mercur-spinner>
                </div>
            </div>
        </mercur-card>
        <div v-if="product && Object.keys(product.remainingAttributes).length" class="filters">
            <div class="d-flex align-items-center">
                <mercur-button class="btn btn-primary" @click="areFiltersDisplayed = !areFiltersDisplayed">
                    Filter attributes and options
                    <i class="fas fa-chevron-down ml-2 mr-0" v-if="!areFiltersDisplayed"></i>
                    <i class="fas fa-chevron-up ml-2 mr-0" v-else></i>
                </mercur-button>
                <small class="ml-auto link" @click="toggleSkuSearch()">
                    Search by {{ isSkuSearchActive ? 'attribute options' : 'SKU' }}
                </small>
            </div>
            <p class="m-0 mt-2 text-grey" v-if="Object.keys(skuFilters).length">
                (<span v-for="(value, key) in skuFilters" :key="key"><strong>{{ key }}: </strong>{{ value }}; </span>)
            </p>
        </div>
        <template v-if="areFiltersDisplayed">
            <div class="filter-chips">
                <template v-if="isSkuSearchActive">
                    <div class="d-flex mt-3">
                        <mercur-input class="flex-1" v-model="sku" @keydown.enter="applySkuFilter()"></mercur-input>
                        <mercur-button class="btn btn-yellow btn-raised mt-0" @click="applySkuFilter()">
                            Search by SKU
                        </mercur-button>
                    </div>
                </template>
                <template v-else>
                    <h4 class="mt-2 mb-1">Attributes</h4>
                    <mercur-chip
                        v-for="availableAttribute in Object.keys(product.remainingAttributes)"
                        :key="availableAttribute"
                        class="mb-1"
                        :clickable="true"
                        :class="selectedAttributeNamesForFiltering.includes(availableAttribute) ? 'bg-primary' : ''"
                        @click="toggleAttributeForFiltering(availableAttribute)"
                    >
                        {{ availableAttribute }}
                    </mercur-chip>
                    <div>
                        <div v-for="attributeName in selectedAttributeNamesForFiltering" :key="attributeName">
                            <p class="attribute"><strong>{{ attributeName | beautify }}</strong></p>
                            <div>
                                <mercur-chip
                                    class="mb-1"
                                    :clickable="true"
                                    :class="{'bg-primary': skuFilters[attributeName] && skuFilters[attributeName] === option}"
                                    v-for="(option, key) in product.remainingAttributes[attributeName]"
                                    :key="key"
                                    @click="updateFilter(attributeName, option)">{{ option | beautify }}
                                </mercur-chip>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end filters-actions pb-3">
                        <mercur-button @click="clearAttributeFilters()" class="btn btn-white btn-raised">
                            <i class="fa fa-close mr-1"></i>
                            Reset filter
                        </mercur-button>
                        <mercur-button
                            @click="applyAttributeFilters()"
                            class="btn btn-raised btn-yellow"
                            :disabled="Object.keys(skuFilters).length === 0">Apply
                        </mercur-button>
                    </div>
                </template>
            </div>
        </template>
        <div class="mb-3">
            <div class="row sku-table my-3" v-if="isAllowedTo('SupplierCentral/listSalesPriceTaskVariations')">
                <div v-if="url" class="col-12 d-flex justify-content-between">
                    <h3 class="mt-0">Price information</h3>
                    <div class="tools pr-0">
                        <pretty-select
                            :options="availableCurrencies"
                            :get-option-label="({code, sign}) => `${code} (${sign})`"
                            placeholder="Currency"
                            v-model="selectedCurrency"
                            :reduce="currency => currency.code"
                            class="currency-selector mr-3"
                            :clearable="false"
                        ></pretty-select>

                        <pretty-select
                            :options="pricingRuleLabels"
                            placeholder="Price Label"
                            v-model="selectedLabel"
                            class="currency-selector mr-3"
                            :clearable="false"
                            label="labelName"
                        ></pretty-select>

                        <mercur-tooltip>
                            <mercur-button
                                :disabled="!Object.keys(skuFilters).some(key => key === this.keyAttribute)"
                                class="btn btn-primary btn-raised ml-auto"
                                @click.native="downloadAssetsWrapper"
                            >
                                Download CSV
                                <i class="fas fa-cloud-download-alt ml-1"></i>
                            </mercur-button>
                            <template slot="label" v-if="!Object.keys(skuFilters).some(key => key === this.keyAttribute)">
                                <span>Apply a key attribute filter to enable the button (eg. type_clothing, type_pen, type_bottle)</span>
                            </template>
                        </mercur-tooltip>

                        <mercur-button class="btn btn-primary btn-bulk-download btn-raised ml-1" @click.native="isBulkExporting = true">
                            <i class="fas fa-download m-1"></i>
                        </mercur-button>
                    </div>
                </div>
                <div class="col-3 position-relative" :class="{'shrinked-skus': !showSkus}">
                    <data-table
                        class="sku-table__data mb-3 border"
                        :options="options"
                        :url="url"
                        ref="grid"
                        @rowClicked="selectSku"
                        data-test="TestPricesSkuTable"
                        v-if="url && isAllowedTo('SupplierCentral/listSalesPriceTaskVariations')"
                        @fetched="handleFetch"
                    ></data-table>
                    <mercur-button
                        v-if="url && quantities.length"
                        class="btn btn-icon btn-raised bg-white btn-hide-skus"
                        @click="showSkus = false"
                    ><i class="fas fa-chevron-left"></i></mercur-button>
                </div>
                <div class="col-9 position-relative pr-5" :class="{'col-12': !showSkus}"
                     v-if="quantities && quantities.length">
                    <mercur-button
                        v-if="url && !showSkus"
                        class="btn btn-raised btn-icon bg-white btn-show-skus"
                        @click="showSkus = true"
                    ><i class="fas fa-chevron-right"></i>
                    </mercur-button>
                    <div class="table-responsive w-100">
                        <table class="margin-table w-100">
                            <thead>
                            <tr>
                                <th>Qty.</th>
                                <th v-for="key in headKeys" :key="key">{{ key }}</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <prices-table-row
                                v-for="quantity in quantities" :key="quantity"
                                :quantity="quantity"
                                :selected-shop="selectedShop"
                                :selected-sku="selectedSku"
                                :selected-product="selectedProduct"
                                :selected-currency="selectedCurrency"
                                :universal-col-num="colNum"
                                :pricing-rule-template-id="form.pricingRuleTemplateId"
                                :pricing-rule-templates="pricingRuleTemplates"
                                :selected-label="selectedLabel"
                                @updateColumns="setNumberOfColumns"
                            />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <mercur-dialog :is-open.sync="isBulkExporting" @close="closeBulkExport()" width="700px">
            <div slot="header">
                <h2>Bulk download CSV</h2>
            </div>
            <div slot="default">
                <div class="mb-3">
                    <b>Note:</b> exporting data for multiple key attributes might take some time.
                </div>

                <pretty-select
                    v-model="bulkExportAttributes"
                    :options="getKeyAttributeOptions()"
                    :multiple="true"
                    :placeholder="getKeyAttributeLabel()"
                    custom-placeholder="Select the values you want to export"
                >
                </pretty-select>
            </div>
            <div slot="footer" class="mb-2">
                <mercur-button class="btn" :disabled="loading" @click="closeBulkExport()">
                    Cancel
                </mercur-button>
                <mercur-button class="btn btn-primary" @click="bulkExportToCsv" :disabled="loading">
                    Download CSV
                    <i class="fas fa-cloud-download-alt ml-1"></i>
                </mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import AgreementSelect from '../../components/agreements/AgreementSelect'
import PricingRuleTemplateSelector from '../../components/pricing/PricingRuleTemplateSelector'
import PricingHelperMethods from '../../components/pricing/PricingHelperMethods'
import PrettySelect from '../../components/utils/PrettySelect'
import DataTable from '../../components/DataTable'
import PricesTableRow from '../../components/pricing/PricesTableRow'
import CONFIG from '@root/config'
import countries from 'country-region-data'
import DownloadMixin from '@/components/DownloadMixin'

export default {
    name: 'PricingTestPrices',
    mixins: [PricingHelperMethods, DownloadMixin],
    components: { AgreementSelect, PricingRuleTemplateSelector, PrettySelect, DataTable, PricesTableRow },
    data () {
        return {
            form: {},
            shops: [],
            product: null,
            products: [],
            loading: false,
            selectedShop: null,
            selectedProduct: null,
            selectedSku: null,
            quantities: [],
            selectedQuantity: null,
            turnarounds: null,
            variationFilter: '',
            fetchingVariations: false,
            areFiltersDisplayed: false,
            isSkuSearchActive: false,
            sku: null,
            countries,
            pricingRuleTemplates: [],
            options: {
                columns: {
                    'SKU': {
                        field: 'sku',
                        cellClass: 'ag-grid__cell--multi-line',
                        autoHeight: true,
                        filterParams: {
                            caseSensitive: true,
                        },
                    },
                },
            },
            colNum: 0,
            headKeys: [],
            showSkus: true,
            selectedAttributeNamesForFiltering: [],
            skuFilters: {},
            selectedCurrency: 'EUR',
            selectedLabel: null,
            pricingRuleLabels: [],
            keyAttribute: null,
            isBulkExporting: false,
            bulkExportAttributes: [],
        }
    },
    watch: {
        'form.agreementId': {
            handler (value) {
                if (!value) {
                    return
                }
                if (value !== this.$route.query.agreementId) {
                    this.$set(this.form, 'pricingRuleTemplateId', null)
                }
                this.$router.push({ path: this.$route.path, query: { ...this.$route.query, agreementId: value } })
                this.resetSelection()
            },
        },
        'form.pricingRuleTemplateId': {
            handler (value) {
                if (!value) {
                    return
                }
                this.resetSelection()
                this.$router.push({
                    path: this.$route.path,
                    query: { ...this.$route.query, pricingRuleTemplateId: value },
                })
                this.loading = true
                this.getShops(this.form.agreementId).then((data) => {
                    this.shops = data.data.items
                }).finally(() => {
                    this.loading = false
                })
                this.getProducts()
                this.getPricingRuleLabels()
            },
        },
        selectedShop (value) {
            this.$set(this, 'selectedProduct', null)
            this.$set(this, 'selectedCurrency', value.currency || 'EUR')
        },
        selectedProduct: {
            handler () {
                this.quantities = []
                this.$set(this, 'selectedSku', null)
                if (this.$refs && this.$refs.grid) {
                    this.$refs.grid.gridApi.clearFocusedCell()
                }
                this.getAttributesAndOptions()
                this.getKeyAttribute()
                this.skuFilters = {}
            },
            deep: true,
        },
    },
    computed: {
        availableCurrencies () {
            return Object.values(this.$store.state.finance.availableCurrencies)
        },
        shopList () {
            if (!this.shops || !this.shops.length) {
                return []
            }

            const availableShops = this.shops
            availableShops.forEach((shop) => {
                const country = this.countries.find(e => e.countryShortCode === shop.countryCode)
                if (country) {
                    shop.countryName = country.countryName
                }
            })

            return availableShops
        },
        productsList () {
            if (!this.products || !this.products.length || !this.selectedShop) {
                return []
            }

            return this.products
        },
        url () {
            if (!this.selectedShop || !this.form.agreementId || !this.selectedProduct) {
                return null
            }

            return CONFIG.API.ROUTES.PRICING.PRICED_SKUS
                .replace('{countryCode}', this.selectedShop.countryCode)
                .replace('{agreementId}', this.form.agreementId)
                .replace('{productConfigurationTemplateId}', this.selectedProduct.productConfigurationTemplateId)
        },
    },
    methods: {
        handleFetch (data) {
            if (this.product && data.length === 0 && this.keyAttribute in this.skuFilters) {
                // if there are no results for a certain combination of attributes, we reset it to the requested keyAttribute
                this.skuFilters = { [this.keyAttribute]: this.skuFilters[this.keyAttribute] }
                this.applyAttributeFilters()
                return
            }

            if (!this.product || data[0].totalRows > data.length) {
                return
            }
            let newSku = []

            // find the common attributes between the results so we can apply the filters
            const attributes = data.map(item => (item.mercurSku || item.sku).split('-'))
            for (let i = 0; i < attributes[0].length; i++) {
                if (attributes.every(attr => attr[i] === attributes[0][i])) {
                    newSku.push(attributes[0][i])
                } else {
                    newSku.push('')
                }
            }

            newSku = newSku.join('-')

            const newSkuFilter = this.getMappedAttributesAndOptionsFromSku(newSku)
            if (newSkuFilter === null) {
                return
            }
            this.skuFilters = newSkuFilter
        },
        toggleSkuSearch () {
            this.isSkuSearchActive = !this.isSkuSearchActive
            this.areFiltersDisplayed = true
        },
        clearAttributeFilters () {
            this.$set(this, 'skuFilters', {})
            this.$set(this, 'selectedAttributeNamesForFiltering', [])
            this.applyAttributeFilters()
        },
        applyAttributeFilters () {
            this.$set(this, 'selectedSku', null)
            this.$set(this, 'quantities', [])
            this.$refs.grid.applyDataSource({ attributes: this.skuFilters })
            this.areFiltersDisplayed = false
            this.sku = this.getSkuFromMappedAttributesAndOptions(this.skuFilters)
        },
        applySkuFilter () {
            this.$set(this, 'selectedSku', null)
            this.$set(this, 'quantities', [])
            this.$set(this, 'skuFilters', {})
            this.$set(this, 'selectedAttributeNamesForFiltering', [])
            this.areFiltersDisplayed = false
            let sku = this.sku.trim()

            // load attributes for presta sku search
            if (sku.includes('+')) {
                this.$refs.grid.applyParams({ attributes: this.product.productVariationAttributes })
            } else {
                this.$refs.grid.applyParams({ attributes: this.skuFilters })
            }

            this.$refs.grid.applyFilter({
                sku: {
                    type: 'contains',
                    filter: sku,
                    filterType: 'text',
                },
            })
            this.selectedAttributeNamesForFiltering = Object.keys(this.product.remainingAttributes)
            const newSkuFilter = this.getMappedAttributesAndOptionsFromSku(sku)
            if (newSkuFilter === null) {
                return
            }
            this.skuFilters = newSkuFilter
        },
        getMappedAttributesAndOptionsFromSku (sku) {
            const attributeOptionValues = sku.toString().split('-')
            const attributeNames = Object.keys(this.product.remainingAttributes)

            if (attributeOptionValues.length !== attributeNames.length) {
                return null
            }

            const attributeOptionsMap = {}
            attributeOptionValues.forEach((optionValue, index) => {
                if (!this.product.productVariationAttributes[attributeNames[index]].includes(optionValue.toString().toLowerCase())) {
                    return
                }
                attributeOptionsMap[attributeNames[index]] = optionValue.toString().toLowerCase()
            })

            return attributeOptionsMap
        },
        getSkuFromMappedAttributesAndOptions (attributesAndOptions) {
            return Object.values(attributesAndOptions).map(option => option.toUpperCase()).join('-')
        },
        toggleAttributeForFiltering (attributeName) {
            if (this.selectedAttributeNamesForFiltering.includes(attributeName)) {
                this.selectedAttributeNamesForFiltering = this.selectedAttributeNamesForFiltering.filter(selectedAttributeName => attributeName !== selectedAttributeName)
                this.$delete(this.skuFilters, attributeName)
                return
            }
            this.selectedAttributeNamesForFiltering.push(attributeName)
            this.selectedAttributeNamesForFiltering.sort()
        },
        getProducts () {
            if (!this.isAllowedTo('getAgreementProductsByAgreementId')) {
                return
            }
            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_PRODUCTS.replace('{agreementId}', this.form.agreementId)
            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))

            this.addJob(url)
            this.$api.post(url, requestPayload).then(({ data }) => {
                this.products = data.data.items
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        getPricingRuleLabels () {
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABELS.LIST
            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))

            this.addJob(url)
            this.$api.post(url, requestPayload).then(({ data }) => {
                this.pricingRuleLabels = data.data.items
                this.selectedLabel = data.data.items[0]
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        selectSku (data) {
            this.selectedQuantity = null
            this.turnarounds = null
            this.$set(this, 'quantities', [])
            this.$set(this, 'colNum', 0)
            this.$set(this, 'headKeys', [])

            if (data === null) {
                return
            }

            this.selectedSku = data.mercurSku || data.sku
            this.quantities = data.quantities
        },
        getAttributesAndOptions () {
            if (!this.selectedShop || !this.selectedProduct) {
                return
            }

            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_ATTRIBUTES_FOR_FUNNEL
                .replace('{shopHash}', this.selectedShop.shopHash)
                .replace('{productHash}', this.selectedProduct.productHash)

            if (!this.isAllowedTo('SupplierCentral/getAttributesForFunnel')) {
                return
            }

            const payload = {
                countryCode: this.selectedShop.countryCode,
            }

            this.addJob(url)
            this.product = null
            this.$api.post(url, payload).then(({ data }) => {
                this.product = data.data
                const remainingAttributes = Object.entries(this.product.remainingAttributes || []).map(([attributeName, options]) => {
                    options.sort((a, b) => a.toString().localeCompare(b.toString(), undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    }))
                    return [attributeName, options]
                })
                remainingAttributes.sort((a, b) => a[0].toString().localeCompare(b[0].toString()))
                this.$set(this.product, 'remainingAttributes', Object.fromEntries(remainingAttributes))
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        resetSelection () {
            this.$set(this, 'shops', [])
            this.$set(this, 'products', [])
            this.$set(this, 'selectedShop', null)
            this.$set(this, 'selectedProduct', null)
            this.$set(this, 'selectedSku', null)
            this.$set(this, 'selectedQuantity', null)
            this.$set(this, 'quantities', [])
            this.$set(this, 'variations', [])
            this.$set(this, 'tasks', [])
        },
        setNumberOfColumns (data) {
            this.$set(this, 'colNum', data.columnNumber)
            this.$set(this, 'headKeys', data.headKeys)
        },
        updateFilter (key, value) {
            if (this.skuFilters[key] && this.skuFilters[key] === value) {
                this.$delete(this.skuFilters, [key])
            } else {
                this.$set(this.skuFilters, [key], value)
            }
        },
        storeTemplates (templates) {
            this.pricingRuleTemplates = templates
        },
        closeBulkExport () {
            this.isBulkExporting = false
            this.bulkExportAttributes = []
        },
        getKeyAttribute () {
            this.keyAttribute = null

            if (!this.selectedProduct) {
                return
            }

            const url = CONFIG.API.ROUTES.PRODUCT_CONFIGURATION_TEMPLATES.GET_KEY_ATTRIBUTE
                .replace('{productConfigurationTemplateId}', this.selectedProduct.productConfigurationTemplateId)

            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                this.$set(this, 'keyAttribute', data.data)
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        getKeyAttributeLabel () {
            if (!this.keyAttribute) {
                return ''
            }

            return this.$options.filters.beautify(this.keyAttribute)
        },
        getKeyAttributeOptions () {
            if (!this.product || !this.keyAttribute) {
                return []
            }

            return this.product.productVariationAttributes[this.keyAttribute] || []
        },
        bulkExportToCsv ($event) {
            if (!this.keyAttribute) {
                return
            }

            if (this.bulkExportAttributes.length === 0) {
                this.$root.$emit('notification:global', {
                    message: 'Please select at least one value to export',
                    type: 'error',
                })

                return
            }

            const skuFilters = {}
            skuFilters[this.keyAttribute] = this.bulkExportAttributes

            this.downloadAssetsWrapper($event, skuFilters)
            this.closeBulkExport()
        },
        downloadAssetsWrapper ($event, skuFilters = null) {
            const payload = {
                shopHash: this.selectedShop.shopHash,
                countryCode: this.selectedShop.countryCode,
                productHash: this.selectedProduct.productHash,
                currency: this.selectedCurrency,
                skuFilters: skuFilters || this.skuFilters,
                attributes: Object.keys(this.product.productVariationAttributes),
                labelDiscount: this.selectedLabel.discount,
            }

            const currentTemplate = this.pricingRuleTemplates.find(e => e.pricingRuleTemplateId === this.$route.query.pricingRuleTemplateId)
            if (currentTemplate.templateStatus !== 'PRODUCTION') {
                payload.pricingRuleTemplateId = this.$route.query.pricingRuleTemplateId
            }

            const url = CONFIG.API.ROUTES.CARRIERS.TASKS.DOWNLOAD
            const jobName = [url, payload.productHash, JSON.stringify(payload.attributes)].join('')
            this.downloadAssets(payload, url, CONFIG.API.ROUTES.CARRIERS.TASKS.CHECK_TOKEN_RESPONSE, 'Download SKU', jobName)
        },
    },
    created () {
        if (this.$route.query.agreementId) {
            this.$set(this.form, 'agreementId', this.$route.query.agreementId)
        }

        if (this.$route.query.pricingRuleTemplateId) {
            this.$set(this.form, 'pricingRuleTemplateId', this.$route.query.pricingRuleTemplateId)
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables.scss';

.agreement-select {
    display: inline-block;
    width: 300px;
    margin-left: 10px;
    vertical-align: middle;
}

.sku-table {
    max-height: calc(100% - 20px);

    &__data {
        border: 1px solid #e2e2e2;
        border-radius: 4px;

        /deep/ .ag-theme-material .ag-ltr .ag-cell-focus {
            background-color: $accent-color;
        }

        /deep/ .ag-theme-material .ag-cell-range-selected-1:not(.ag-cell-focus) {
            background-color: white;
        }
    }
}

.table-responsive {
    max-height: 100%;
    overflow-y: scroll;
}

.shrinked-skus {
    display: none;

    .sku-table__data {
        border: none;
    }
}

.btn-bulk-download {
    min-width: auto;
}

.btn-hide-skus {
    position: absolute;
    top: 0;
    right: 0;

    i {
        font-size: 14px;
    }
}

.btn-show-skus {
    position: absolute;
    left: 0;
    top: 0px;

    i {
        font-size: 14px;
    }
}

.filters {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 3;
    border-bottom: 1px solid $pricing-border;
    margin-right: -15px;
    margin-left: -15px;
    padding: 15px;
}

.filter-chips {
    border-bottom: 1px solid $pricing-border;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0 15px;
}

.filters-actions {
    position: sticky;
    bottom: 0;
}

.tools {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    padding-right: 12px;
}

.currency-selector {
    width: 200px;
}

.attribute {
    margin-bottom: 10px;
    border-top: 1px solid #DCDFE2;
    padding-top: 10px;
}

/deep/ .chip {
    height: 24px;
}
</style>
