import { render, staticRenderFns } from "./PricesTableRow.vue?vue&type=template&id=6554a901&scoped=true&"
import script from "./PricesTableRow.vue?vue&type=script&lang=js&"
export * from "./PricesTableRow.vue?vue&type=script&lang=js&"
import style0 from "./PricesTableRow.vue?vue&type=style&index=0&id=6554a901&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6554a901",
  null
  
)

export default component.exports