<template>
    <tr>
        <td>{{ quantity }}</td>
        <template v-if="turnaroundsWereLoaded(turnarounds)">
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ turnarounds.CHEAPEST.turnaround }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ turnarounds.FASTEST.turnaround }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - turnaround`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ value.turnaround }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.CHEAPEST.supplierCost).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.FASTEST.supplierCost).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - supplierCost`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ convertToSelectedCurrency(value.supplierCost).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.CHEAPEST.carrierCost).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.FASTEST.carrierCost).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - carrierCost`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ convertToSelectedCurrency(value.carrierCost).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ turnarounds.CHEAPEST.serviceLevel }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ turnarounds.FASTEST.serviceLevel }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - serviceLevel`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ value.serviceLevel }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ turnarounds.CHEAPEST.packs }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ turnarounds.FASTEST.packs }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - packs`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ value.packs }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ (turnarounds.CHEAPEST.unitWeight * quantity).toFixed(2) }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ (turnarounds.FASTEST.unitWeight * quantity).toFixed(2) }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - unitWeight`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ (value.unitWeight * quantity).toFixed(2) }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ turnarounds.CHEAPEST.countryFrom }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ turnarounds.FASTEST.countryFrom }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - unitWeight`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ value.countryFrom }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.CHEAPEST.supplierPrice).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.FASTEST.supplierPrice).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - supplierPrice`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ convertToSelectedCurrency(value.supplierPrice).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.CHEAPEST.carrierPrice).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ convertToSelectedCurrency(turnarounds.FASTEST.carrierPrice).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - shipping`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ convertToSelectedCurrency(value.carrierPrice).toFixed(2) | asMoney(selectedCurrency) }}</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p class="text-nowrap">
                        {{ convertToSelectedCurrency(turnarounds.CHEAPEST.turnaroundPrice).toFixed(2) | asMoney(selectedCurrency) }}
                        <mercur-tooltip v-if="turnarounds.CHEAPEST.turnaroundPrice !== turnarounds.CHEAPEST.latestTurnaroundPrice">
                            <i class="fa fa-info-circle text-light-blue"></i>
                            <template slot="label">
                                <span>
                                    This is a locked sales price.
                                    Latest: {{ convertToSelectedCurrency(turnarounds.CHEAPEST.latestTurnaroundPrice).toFixed(2) | asMoney(selectedCurrency) }}
                                </span>
                            </template>
                        </mercur-tooltip>
                    </p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p class="text-nowrap">
                        {{ convertToSelectedCurrency(turnarounds.FASTEST.turnaroundPrice).toFixed(2) | asMoney(selectedCurrency) }}
                        <mercur-tooltip v-if="turnarounds.FASTEST.turnaroundPrice !== turnarounds.FASTEST.latestTurnaroundPrice">
                            <i class="fa fa-info-circle text-light-blue"></i>
                            <template slot="label">
                                <span>
                                    This is a locked sales price.
                                    Latest: {{ convertToSelectedCurrency(turnarounds.FASTEST.latestTurnaroundPrice).toFixed(2) | asMoney(selectedCurrency) }}
                                </span>
                            </template>
                        </mercur-tooltip>
                    </p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - price`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p class="text-nowrap">
                        {{ convertToSelectedCurrency(value.turnaroundPrice).toFixed(2) | asMoney(selectedCurrency) }}
                        <mercur-tooltip v-if="value.turnaroundPrice !== value.latestTurnaroundPrice">
                            <i class="fa fa-info-circle text-light-blue"></i>
                            <template slot="label">
                                <span>
                                    This is a locked sales price.
                                    Latest: {{ convertToSelectedCurrency(value.latestTurnaroundPrice).toFixed(2) | asMoney(selectedCurrency) }}
                                </span>
                            </template>
                        </mercur-tooltip>
                    </p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{ cheapestMargin }} %</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{ fastestMargin }} %</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - supplierCost`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ calculateStepMargin(value) }} %</p>
                </div>
            </td>
            <td>
                <div>
                    <p class="inner-label">Cheapest</p>
                    <p>{{turnarounds.CHEAPEST.supplierName}}</p>
                </div>
                <div>
                    <p class="inner-label">Fastest</p>
                    <p>{{turnarounds.FASTEST.supplierName}}</p>
                </div>
                <div v-for="(value, step) in turnarounds.steps" :key="`step - ${step} - supplierName`">
                    <p class="inner-label">Step {{ step }}</p>
                    <p>{{ value.supplierName }}</p>
                </div>
            </td>
        </template>
        <template v-if="universalColNum > totalValues">
            <td v-for="col in universalColNum - totalValues" :key="col">&nbsp;</td>
        </template>
        <td class="see-prices">
            <mercur-spinner v-if="isLoading"></mercur-spinner>
            <button v-else-if="!turnarounds" data-test="pricesTableRowShowPricesButton" class="btn btn-raised bg-white see-prices-btn" @click="getProductTurnarounds">See prices</button>
            <button v-else class="btn btn-raised bg-white see-prices-btn" @click="getProductTurnarounds">Recalculate</button>
        </td>
    </tr>
</template>

<script>
import CONFIG from '@root/config'
import { mapState } from 'vuex'
export default {
    name: 'PricesTableRow',
    props: {
        quantity: {
            required: true,
        },
        selectedShop: {
            required: true,
        },
        selectedProduct: {
            required: true,
        },
        selectedSku: {
            required: true,
        },
        selectedCurrency: {
            required: true,
        },
        pricingRuleTemplateId: {
            required: true,
        },
        universalColNum: {
            default: 0,
        },
        pricingRuleTemplates: {
            default: [],
        },
        selectedLabel: {
            required: true,
        },
    },
    data () {
        return {
            turnarounds: null,
            totalValues: 0,
            headKeys: ['Turnaround', 'Supplier Cost', 'Carrier Cost', 'Service Level', 'Packs', 'Weight', 'Facility', 'Supplier Price', 'Carrier Price', 'Sales Price', 'GPM', 'Supplier'],
            isLoading: false,
            turnaroundsURL: CONFIG.API.ROUTES.CARRIERS.GET_PRODUCT_TURNAROUNDS,
        }
    },
    computed: {
        ...mapState('finance', ['availableCurrencies']),
        selectedCurrencySign () {
            return this.availableCurrencies[this.selectedCurrency].sign
        },
        cheapestMargin () {
            if (!this.turnarounds) {
                return 'xx'
            }

            return this.calculateStepMargin(this.turnarounds.CHEAPEST)
        },
        fastestMargin () {
            if (!this.turnarounds) {
                return 'xx'
            }

            return this.calculateStepMargin(this.turnarounds.FASTEST)
        },
    },
    methods: {
        getProductTurnarounds () {
            const url = this.turnaroundsURL

            if (!this.isAllowedTo('SupplierCentral/getProductTurnarounds')) {
                return
            }
            this.isLoading = true

            const payload = {
                shopHash: this.selectedShop.shopHash,
                productHash: this.selectedProduct.productHash,
                sku: this.selectedSku,
                quantity: this.quantity,
                countryCode: this.selectedShop.countryCode,
                currency: this.selectedCurrency,
                enhancedTurnaround: true,
                labelDiscount: this.selectedLabel.discount,
            }

            const currentTemplate = this.pricingRuleTemplates.find(e => e.pricingRuleTemplateId === this.pricingRuleTemplateId)
            if (currentTemplate.templateStatus !== 'PRODUCTION') {
                payload.pricingRuleTemplateId = this.pricingRuleTemplateId
            }

            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                this.turnarounds = data.data.turnarounds
                this.dealWithTurnarounds()
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
                this.isLoading = false
            })
        },
        dealWithTurnarounds () {
            if (!this.turnaroundsWereLoaded(this.turnarounds)) {
                this.$root.$emit('notification:global', {
                    message: 'There seems to be no turnarounds. You can try fetching them again.',
                    type: 'error',
                })
                return
            }

            this.emitUpdates()
        },
        emitUpdates () {
            this.$set(this, 'totalValues', this.headKeys.length)
            this.$emit('updateColumns', {
                columnNumber: this.totalValues,
                headKeys: this.headKeys,
            })
        },
        calculateStepMargin (step) {
            const cost = step.carrierCost + step.supplierCost

            return ((step.turnaroundPrice - cost) / step.turnaroundPrice * 100).toFixed(2)
        },
        convertToSelectedCurrency (value) {
            return this.$store.getters['finance/convertValueObjectTo']({ currency: 'EUR', value: value }, this.selectedCurrency).value
        },
        turnaroundsWereLoaded (turnarounds) {
            if (!turnarounds) {
                return false
            }

            const turnaroundArray = turnarounds.turnarounds || turnarounds

            return !(Array.isArray(turnaroundArray) && !turnaroundArray.length)
        },
    },
    watch: {
        selectedSku () {
            this.$set(this, 'turnarounds', null)
            this.$set(this, 'totalValues', 0)
        },
    },
}
</script>

<style lang="scss" scoped>
    td {
        &:first-child {
            width: 60px;
            max-width: 60px;
        }
        &:nth-child(n + 2) {
            min-width: 100px;
        }
        p {
            margin: 0;
        }
    }
    td.see-prices {
        min-width: 122px;
        text-align: right;
        max-width: 122px;
        width: 122px;
        height: 67px;
    }
    .inner-label {
        font-size: 10px;
        opacity: 0.7;
        line-height: 12px;
    }
    .see-prices-btn {
        white-space: nowrap;
    }
</style>
